<template>
  <div>
    <div class="rsf-compare-table-heading-row flex justify-between pointer" @click="show = !show">
      <h2 class="open-sans-bold color-black h4">{{title}}</h2>
      <div class="md-pr3">
        <svg :style="`transform: rotate(${show ? '0' : '180deg'})`" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div v-show="show">
      <slot></slot>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'TableSection',
  props: {
    title: String,
  },
  data() {
    return {
      show: true
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
