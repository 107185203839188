<template>
  <button :class="`add-product-button mx-auto center pointer block ${isSelected ? 'product-card-selected' : ''}`">
    <svg :class="`${isSelected ? 'svg-color-6' : ''}`"  width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.1148 7.41483H9.48517V1.78517C9.48517 1.57693 9.40245 1.37722 9.2552 1.22997C9.10795 1.08272 8.90824 1 8.7 1C8.49176 1 8.29205 1.08272 8.1448 1.22997C7.99755 1.37722 7.91483 1.57693 7.91483 1.78517V7.41483H2.28517C2.07693 7.41483 1.87722 7.49755 1.72997 7.6448C1.58272 7.79205 1.5 7.99176 1.5 8.2C1.5 8.40824 1.58272 8.60795 1.72997 8.7552C1.87722 8.90245 2.07693 8.98517 2.28517 8.98517H7.91483V14.6148C7.91483 14.8231 7.99755 15.0228 8.1448 15.17C8.29205 15.3173 8.49176 15.4 8.7 15.4C8.90824 15.4 9.10795 15.3173 9.2552 15.17C9.40245 15.0228 9.48517 14.8231 9.48517 14.6148V8.98517H15.1148C15.3231 8.98517 15.5228 8.90245 15.67 8.7552C15.8173 8.60795 15.9 8.40824 15.9 8.2C15.9 7.99176 15.8173 7.79205 15.67 7.6448C15.5228 7.49755 15.3231 7.41483 15.1148 7.41483Z"
        fill="#1867FF" />
    </svg>
  </button>
</template>

<script>
// import axios from 'axios'
import { EventBus } from '@/event-bus'

export default {
  name: 'AddBtn',
  props: {
    product: Object,
    selectedProducts: Array,
    toggleSelectedProduct: Function,
    isSelected: Boolean
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {
    
  }
}
</script>

<style scoped>

</style>
