import ProductSelection from './components/ProductSelection.vue'
import Comapre from './components/Compare.vue'
import { i18n } from "./main.js";

export default [
    { 
        path: '/:lang', 
        component: ProductSelection,
    },
    { 
        path: '/:lang/compare', 
        component: Comapre,
    },
    {
      path: "*",
      redirect: "/en"
    }
]