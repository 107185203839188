<template>
  <div :class="`compare-popup story_atom stb_modal border-box col-12 ${showModal ? 'stb_modal_active' : ''}`" @click="toggleModal">
    <div @click.stop="" class="content-wrapper max-width-3 col-12 py4 px2 bg-color-white border-box">
      <h2 class="open-sans-bold h1 center mb2">{{modalHeading}}</h2>
      <p class="open-sans-regular h4 max-width-1 center mx-auto mb3 color-13">{{modalMessage}}</p>
      <a class="rsf-btn rsf-btn-primary mx-auto bg-color-1 color-white" @click="toggleModal">{{$t('rsf_gotIt')}}</a>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'LightBox',
  props: {
    showModal: Boolean,
    toggleModal: Function,
    modalMessage: String,
    modalHeading: String
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
