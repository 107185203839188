<template>
  <div :class="`rsf-compare-product-card bg-color-white pointer ${isSelected ? 'card-selected' : ''}`" style="border-radius: 4px"  @click="toggleSelectedProduct(product)">
    <img class="col-12 contain" :src="productImg.ImageUrl" />
    <div class="px1 pb2">
      <Rating :rating=product.Rating :showRatingNumber="true" :altVersion="false" :isLink="false" :reviewPageLink="product.ReviewsURL" v-if="parseFloat(product.Rating) > 0 && chinaVariant == 'false'"/> 
      <h4 class="h4 helvetica-bold color-black center">{{product.Model}}</h4>
      <p class="center h6 color-13 open-sans-regular mb2">{{measurement == 'metric' ? product.LengthMetric + ' cm' : product.LengthImperial + '"'}} | {{measurement == 'metric' ? product.LiterMetric + ' L' : product.GallonMetric + ' gal'}}</p>
      <AddBtn :product="product" :selectedProducts="selectedProducts" :isSelected="isSelected" />
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'
import Rating from './Rating.vue'
import AddBtn from './AddBtn.vue'

export default { 
  name: 'ProductCard',
  props: {
    product: Object,
    selectedProducts: Array,
    toggleSelectedProduct: Function,
    measurement: String
  },
  components: {
    Rating,
    AddBtn
  },  
  data() {
    return {
      chinaVariant: process.env.VUE_APP_RSF_CHINA_VARIANT
    }
  },
  created() {

  },
  computed: {
    productImg () {
      return {
        ...this.product,
        img: this.product.img && require(`../assets/images/${this.product.img}`)
      }
    },
    isSelected () {
      for(let i = 0; i < this.selectedProducts.length; i++){
        if(this.selectedProducts[i].id == this.product.id){
          return true
        }
      }
      return false
    }
  },  
  methods: {

  }
}
</script>

<style scoped>

</style>
