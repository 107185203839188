<template>
  <div class="mx-auto col-12 center relative mb3 range-filter-slider">
    <div :class="`range-value-wrapper ${measurement == 'metric' ? '' : 'range-values-imperial'}`">
        <div class="range-value open-sans-regular h6"><span></span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 50 : 20}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 75 : 30}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 100 : 39}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 125 : 49}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 150 : 59}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 175 : 69}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 200 : 79}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value tick open-sans-semibold h6">{{measurement == 'metric' ? 225 : 89}}<span>{{measurement == 'metric' ? 'cm' : '"'}}</span></div>
        <div class="range-value open-sans-regular h6"><span></span></div>
    </div>
    <div id="range-slider" class="range-slider flat" data-ticks-position='top' :style="`--min:1; --max:10; --value-a:-1; --value-b:10;`">
      <input v-model="lowerCap" type="range" min="1" max="10" value="1"
        oninput="this.parentNode.style.setProperty('--value-a',this.value);">
      <input v-model="upperCap" type="range" min="1" max="10" value="10"
        oninput="this.parentNode.style.setProperty('--value-b',this.value);">
      <div class='range-slider__progress'></div>
  </div>

  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'FilterSlider',
  props: {
    updateUpperCap: Function, 
    updateLowerCap: Function,
    measurement: String,
    computedHiCap: Number, 
    computedLowCap: Number
  },  
  data(){
    return {
      lowerCap: 1, 
      upperCap: 10
    }
  },  
  created() {
    this.updateUpperCap(this.upperCap)
    this.updateLowerCap(this.lowerCap)
  },
  methods: {

  },
  watch: {
    upperCap (newValue, oldValue){
      if(Number(newValue) == Number(this.lowerCap) || Number(newValue) < Number(this.lowerCap)){
        this.upperCap = oldValue
        let slider = document.getElementById('range-slider');
        slider.style.setProperty('--value-b', oldValue)
      }
      this.updateUpperCap(this.upperCap)
    },
    lowerCap (newValue, oldValue){
      if(Number(newValue) == Number(this.upperCap)){
        //console.log('condition lowercap')
        this.lowerCap = oldValue
        let slider = document.getElementById('range-slider');
        slider.style.setProperty('--value-a', oldValue)
      }
      if(Number(newValue) > Number(this.upperCap)){
        this.lowerCap = oldValue
        let slider = document.getElementById('range-slider');
        slider.style.setProperty('--value-a', oldValue)
      }
      this.updateLowerCap(this.lowerCap)
    }
  },
}
</script>

<style scoped>

</style>
