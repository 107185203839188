// src/lang/index.js
import de from './translations/de';
import en from './translations/en';
import br from './translations/br';
import fr from './translations/fr';
import ja from './translations/ja';
import cn from './translations/cn';
export default {
  en,
  de,
  fr, 
  br,
  ja,
  cn
};