<template>
  <div class="rsf-compare-title-dropdown-wrapper gridbox px1">
    <div  class="gridbox-element relative">
    <h2 class="open-sans-bold color-black h6 md-h4 w-fit-content mx-auto  center px1">{{selectedProduct.Model}}</h2>
    <svg class="svg-color-1" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
  </div>
  <select ref="titleDropdown" v-model="selectedProduct" class="mx-auto block col-12 op0 gridbox-element z2 pointer">
    <option v-for="product2 in cleanProductArray" :key="product2.id" :value="product2" v-if="isNotSelected(product2.id)">{{product2.Model}}</option>
  </select>
</div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'


export default {
  name: 'CompareTankDropdown',
  props: {
    product: Object,
    selectedProducts: Array,
    categories: Array,
    updateSelectedProducts: Function,
    computedHiCap: Number,
    computedLowCap: Number
  },
  data() {
    return {
      selectedProduct: this.product,
    }
  },
  created() {

  },
  methods: {
    isNotSelected(id){
      for(let i = 0; i < this.selectedProducts.length; i++){
        if(this.selectedProducts[i].id == id){
          return false
        }
      }
      return true
    }
  },
  computed: {
    cleanProductArray () {
      let arrayToBeFilled = []
      for(let i = 0; i < this.categories.length; i++){
        for(let x = 0; x < this.categories[i].dataProducts.length; x++){
          if(this.categories[i].dataProducts[x].LengthMetric < this.computedLowCap || this.categories[i].dataProducts[x].LengthMetric > this.computedHiCap){
            continue;
          }
          arrayToBeFilled.push(this.categories[i].dataProducts[x])
        }
      }
      return arrayToBeFilled
    }
  },  
  watch: {
    selectedProduct (newValue, oldValue){
      if(newValue != oldValue){
        window.scrollTo(0,0);
        this.updateSelectedProducts(newValue, oldValue);
      }
    } 
  }
}
</script>

<style scoped>

</style>
