<template>
  <div class="rsf-table-cell col-12">
    <p class="open-sans-var h5 color-black center">{{
      measurement == 'metric' && metricValue && metricValue != '' ? metricValue
      :
      imperialValue && imperialValue != '' ? imperialValue
      : '-'
    }}</p>
  </div>

</template>

<script>

export default {
  name: 'StringCell',
  props: {
    measurement: String,
    metricValue: String,
    imperialValue: String,
  }
}
</script>
