<template>
  <div class="rsf-compare-unit-switch relative flex gap1 justify-betweem pointer" @click="useCm = !useCm; changeMeasurement(useCm)">
    <span v-show="useCm" class="open-sans-semibold color-13 col-12">inch</span>
    <span v-show="!useCm" class="open-sans-semibold color-13 right-align col-12">cm</span>
    <div class="switch-thumb" :style="`left:${useCm ? '35px' : '-14px'}` "  >
      <span class="open-sans-bold">{{useCm ? 'cm' : 'inch'}}</span>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default { 
  name: 'CustomSwitch',
  data() {
    return {
      useCm: true
    }
  },
  props: {
    changeMeasurement: Function,
    measurement: String
  },  
  created() {

  },
  mounted(){
    if(this.measurement == 'metric'){
      this.useCm = true;
    }else{
      this.useCm = false;
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
