import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueI18n from 'vue-i18n';
import messages from './lang';
import Routes from "./routes";

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(VueRouter);

const router = new VueRouter({
  // mode:'history',
  routes: Routes
});

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
});

new Vue({
  i18n,
  render: function (h) { return h(App, {
    props: {
      siteLanguage: ""+ document.getElementById('app').attributes.siteLanguage.value
    }
  })},
  router:router
}).$mount('#app')
