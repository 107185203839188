<template>
  <div class="rsf-country-select-wrapper relative">
    <svg :style="`transform: rotate(180deg) translateY(50%)`" width="24" height="24" viewBox="0 0 24 24"
          fill="none">
          <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
  <select @change="updateCountry(selectedCountry)" v-model="selectedCountry" class="rsf-country-select color-14 pointer h5">
    <option :value="{}" disabled selected hidden>{{$t('rsf_compare_retail_price_country_select_placeholder')}}</option>
    <option v-for="country in countries" :key="country.id" :value="country">{{country.countryname}}</option>
  </select>
</div>
</template>

<script>
import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'CountrySelect',
  props: {
    updateCountry: Function,
    siteLanguage: String
  },
  data() {
    return {
      countries: [],
      selectedCountry: {}
    }
  },
  created() {

  },
  mounted(){
    console.log('countrySElect mounted. fetching countries')
    let that = this
    axios
          .post(process.env.VUE_APP_RSF_API + "/public-api/list-countries/compare/json", {'lang': this.siteLanguage })
          .then(function(response) {
            that.countries = response.data.data;
            for(let i = 0; i < that.countries.length; i++){
              if(that.countries[i].isocode2 == 'XX'){
                console.log('remove XX')
                that.countries.splice(i, 1);
              }
              if(that.countries[i].isocode2 == 'JA'){
                console.log('remove JA')
                that.countries.splice(i, 1);
              }
              if(that.countries[i].isocode2 == 'CN' && process.env.VUE_APP_RSF_CHINA_VARIANT == 'false'){
                that.countries.splice(i, 1);
              }
            }
            that.preSelectCountry();
            // that.selectedCountry = response.data.data[0]
          })
          .catch(function(error) {
            
          });

  },
  methods: {
    preSelectCountry(){
      console.log('start preselect country')
      for(let i = 0; i < this.countries.length; i++){
        if(this.countries[i].preselection_by_ip){
          console.log('preselected country by ip!')
          console.log(this.countries[i])
          this.selectedCountry = this.countries[i];
          this.updateCountry(this.countries[i]);
          return; 
        }
      }
      console.log('looking for locales and sitelanguages...');
      console.log('siteLanguage')
      console.log(this.siteLanguage)
      console.log('locale')
      console.log(this.$i18n.locale)
      if(this.siteLanguage == 'cn'){
        this.findCountry('CN')
        return;
      }
      else if(this.$i18n.locale == 'de'){
        this.findCountry('DE')
      }
      else if(this.$i18n.locale == 'fr'){
        this.findCountry('FR')
      }
      else if(this.$i18n.locale == 'en'){
        this.findCountry('US')
      }
      else if(this.$i18n.locale == 'br'){
        this.findCountry('US')
      }
      else if(this.$i18n.locale == 'ja'){
        this.findCountry('GB')
      }else{
        this.findCountry('US') // fallback US
      }
    },
    findCountry(isocode){
      console.log('findCountry')
      console.log(isocode)
      for(let a = 0; a < this.countries.length; a++){
          if(this.countries[a].isocode2 == isocode){
            console.log('country found!')
            console.log(this.countries[a])
            this.selectedCountry = this.countries[a]
            this.updateCountry(this.countries[a]);
          }
        }
    }
  }
}
</script>

<style scoped>

</style>
