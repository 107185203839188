<template>
  <div class="rsf-categroy-wrapper max-width-7 mb5 mx-auto" v-if="visibleProducts > 0">
    <h3 class="helvetica-bold h3 mb3 color-black center sm-left-align"><span v-html="category.categoryname"></span><span class="rsf-compare-category-title-divider" v-if="category.subtitle"> | </span><span class="helvetica-roman rsf-compare-category-subtitle">{{category.subtitle}}</span></h3>
    <div class="rsf-category-grid xxl-grid-6-col lg-grid-5-col md-grid-4-col sm-grid-3-col grid-2-col gap2">
      <ProductCard
        v-for="product in category.dataProducts"
        :product="product"
        :selectedProducts="selectedProducts"
        :toggleSelectedProduct="toggleSelectedProduct"
        :key="product.id"
        :measurement="measurement"
        v-if="product.LengthMetric >= computedLowCap && product.LengthMetric <= computedHiCap"
      />
    </div>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'
import ProductCard from './ProductCard.vue'

export default { 
  name: 'CategoryGrid',
  props: {
    category: Object,
    selectedProducts: Array,
    toggleSelectedProduct: Function,
    computedLowCap: Number,
    computedHiCap: Number,
    measurement: String,
    loadingCategories: Boolean
  },
  components: {
    ProductCard
  },
  data() {
    return {
    }
  },
  created() {

  },
  mounted(){
    if(this.category.subtitle == null ){
      //console.log('is null')
    }
  },
  methods: {

  },
  computed: {
    visibleProducts () {
      let visibleProducts = 0
      for(let i = 0; i < this.category.dataProducts.length; i++){
        if(this.category.dataProducts[i].LengthMetric >= this.computedLowCap && this.category.dataProducts[i].LengthMetric <= this.computedHiCap){
          visibleProducts ++;
        }
      }
      return visibleProducts
    }
  }
}
</script>

<style scoped>

</style>
