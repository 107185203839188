<template>
  <a :href="isLink ? reviewPageLink : null" target="_blank" rel="noopener" :class="`mx-auto col-12 center flex justify-center items-center rsf-product-rating pointer ${isInTable ? 'rsf-star-product-rating' : ''}`" style="gap: 5px">
    <Star :fill="splitRating[0]" clipId="1"/>
    <Star :fill="splitRating[1]" clipId="2"/>
    <Star :fill="splitRating[2]" clipId="3"/>
    <Star :fill="splitRating[3]" clipId="4"/>
    <Star :fill="splitRating[4]" clipId="5"/>
    <p v-if="showRatingNumber" :class="`${altVersion ? 'open-sans-semibold lg-h6 p-xs color-black underline nowrap' : 'open-sans-bold h6 color-14 nowrap'}`" style="margin-left: 1px;">{{ rating }}</p>
  </a>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'
import Star from './Star'

export default {
  name: 'Rating',
  props: {
    rating: String,
    showRatingNumber: Boolean,
    altVersion: Boolean,
    reviewPageLink: String,
    isInTable: Boolean,
    isLink: Boolean
  },
  components: {
    Star
  },  
  data() {
    return {
      star1Fill: 0,
      star2Fill: 0,
      star3Fill: 0,
      star4Fill: 0,
      star5Fill: 0,
    }
  },
  computed: {
    splitRating () {
      let arr = []
      let totalRating = (parseFloat(this.rating)/5)*100;
 
      let v1 = totalRating - 20
      if (v1 >= 0){ 
        arr[0] = 100 
      } else {
        arr[0] = totalRating / 20 * 100; 
        return [arr[0], 0, 0, 0, 0]
      }
      let v2 = v1 - 20
      if (v2 >= 0) { 
        arr[1] = 100 
      } else { 
        arr[1] = v1 / 20 * 100;  
        return [arr[0], arr[1], 0, 0, 0]
      }
      let v3 = v2 - 20
      if(v3 >= 0){ 
        arr[2] = 100 
      } else { 
        arr[2] = v2 / 20 * 100;  
        return [arr[0], arr[1], arr[2], 0, 0]
      }
      let v4 = v3 - 20
      if(v4 >= 0){ 
        arr[3] = 100 
      } else { 
        arr[3] = v3 / 20 * 100; 
        return [arr[0], arr[1], arr[2], arr[3], 0] 
      }
      let v5 = v4 - 20
      if (v5 >= 0){ 
        arr[4] = 100 
      } else { 
        arr[4] = v4 / 20 * 100; 
        return [arr[0], arr[1], arr[2], arr[3], arr[4]] 
      }
      
      return arr
    }
  },  
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>
.p-xs{
  font-size: 0.75rem;
}

@media(min-width: 64em){
  .lg-h6.p-xs{
    font-size: 0.875rem;
  }
}

@media(max-width: 600px){
  .rsf-star-product-rating{
    transform: scale(0.75);
    position: absolute; 
    top: 50%; 
    left: -50%;
    margin: 0 auto;
  }
}

@media(max-width: 400px){
  .rsf-star-product-rating{
    transform: scale(0.6);
  }
}
</style>
