<template>
    <div class="bg-color-11 pt3  md-pt4" style="min-height: 100vh">
        <div class="max-width-4 mx-auto px2 border-box">
            <!-- double mustache syntax fpr translation keys -->
            <h1 class="h0 center helvetica-bold color-black mb1 px2 compare-title" @click="toggleSelectedProduct('Hello')">
                {{ $t('rsf_compare_title') }}</h1>
            <h2 class="compare-subtitle-desktop h2 helvetica-roman center color-black mb2">{{ $t('rsf_compare_subtitle') }}</h2>
            <h2 class="compare-subtitle-mobile h2 helvetica-roman center color-black mb2">{{ $t('rsf_compare_subtitle_mobile') }}</h2>
        </div>
        <div class="col-12 bg-color-11 pb3 z2 lg-pt4 pt3 sticky-filter-slider px2 border-box">
            <div  class="max-width-3 mx-auto">
                <FilterSlider :updateUpperCap="updateUpperCap" :updateLowerCap="updateLowerCap"
                    :measurement="measurement" :computedHiCap="computedHiCap" :computedLowCap="computedLowCap" />
                <div class="grid-2-col sm-grid-3-col items-center mt3 relative">
                    <CustomSwitch :changeMeasurement="changeMeasurement" :measurement="measurement"/>
                    <router-link :to="'/' + this.$i18n.locale + '/compare'"
                        :class="['rsf-btn rsf-btn-primary mx-auto color-white compare-systems-btn h4', selectedProducts.length > 1 ? 'bg-color-1 pointer-events-all' : 'bg-color-inactive pointer-events-none']">
                        {{ $t('rsf_compare_system_button') }}

                    </router-link>
                </div>
            </div>
        </div>
        <div class="relative max-width-6 mx-auto col-12 border-box mt3 pb5 px2 border-box" style="min-height: 500px;">
            <div :class="`compare_loader flex flex-column items-center ${loadingCategories ? 'loading' : ''} `">
                <div class="lds-roller mx-auto mt5">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <h2 class="center h3 color-black mt2">{{ $t('rsf_loading') }}</h2>
            </div>
            <CategoryGrid v-for="category in categories" :category="category" :key="category.id"
                :selectedProducts="selectedProducts" :toggleSelectedProduct="toggleSelectedProduct"
                :computedHiCap="computedHiCap" :computedLowCap="computedLowCap" :measurement="measurement"
                :loadingCategories="loadingCategories" />
        </div>
    </div>
</template>
  
<script>

import FilterSlider from './FilterSlider.vue'
import CustomSwitch from './CustomSwitch.vue'
import CategoryGrid from './CategoryGrid.vue'

export default {
    name: 'ProductSelection',
    data() {
        return {
            msg: this.$t('translations_test'), //how to use translation-key in data

        }
    },
    components: {
        FilterSlider,
        CustomSwitch,
        CategoryGrid
    },
    props: {
        categories: Array,
        selectedProducts: Array,
        measurement: String,
        toggleSelectedProduct: Function,
        changeMeasurement: Function,
        updateUpperCap: Function,
        updateLowerCap: Function,
        computedHiCap: Number,
        computedLowCap: Number,
        loadingCategories: Boolean
    },
}
</script>
  
<style>

</style>
  