<template>
  <div class="pb5 relative bg-color-11" style="min-height: 100vh">

    <div :class="`compare_loader flex flex-column items-center ${loading ? 'loading' : ''} `">
      <div class="lds-roller mx-auto mt5">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h2 class="center h3 color-black mt2">{{ $t('rsf_loading') }}</h2>
    </div>


    <div class="max-width-7 md-px1 px0 mx-auto">
      <div class="items-center  pt3  md-pt4 md-pb4 pb3 px1 relative">
        <router-link :to="'/' + this.$i18n.locale" class="rsf-btn rsf-btn-tertiary color-1 compare-back-link">
          <svg class="svg-color-1" :style="`transform: rotate(-90deg);`" width="24" height="24" viewBox="0 0 24 24"
            fill="none">
            <path d="M17 15L12 10L7 15" stroke="black" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
          <span class="compare-desktop-back-btn">{{ $t('rsf_goBack') }}</span>
        </router-link>
        <h1 class="h1 center helvetica-bold color-black compare-table-title">{{ $t('rsf_compare_result_title') }}</h1>
      </div>

      <!-- Aquariums to compare -->
      <div class="tank-table-row-wrapper">
        <div class="tank-table-row">
          <div class="rsf-compare-table-heading-row sticky-table-row">
            <h2 class="open-sans-bold color-black h4">{{ $t('rsf_result_aquariumToCompareTitle') }}</h2>
          </div>
          <div class="flex">
            <div class="rsf-table-cell col-12"></div>
            
            <div class="rsf-table-cell tank-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
              <a :href="product.ProductsURL ? product.ProductsURL : '#'">
                <img class="col-12 contain pb2 rsf-compare-table-product-img" :src="product.ImageUrl" />
              </a>
              <div class="pb2 relative w-fit-content mx-auto rating-cell">
                <Rating v-if="parseFloat(product.Rating) > 0 && chinaVariant == 'false'" :rating="product.Rating" :showRatingNumber="true" :altVersion="true" :isLink="true" :reviewPageLink="product.ReviewsURL + `#/${siteLanguage}/model=${product.RNumber}`" :isInTable="true"/>
              </div>
              <CompareTankDropdown :product="product" :selectedProducts="selectedProducts" :categories="categories"
                :updateSelectedProducts="updateSelectedProducts" :computedLowCap="computedLowCap" :computedHiCap="computedHiCap" />
            </div>
          </div>
        </div>
      </div>
      <!-- Comapre Table -->
      <div class="rsf-compare-table-wrapper">
        <div class="rsf-compare-table">

          <!-- AR Links -->
          <TableSection v-if="displayARRow" :title=rsf_seeTank_Title>
          <div class="flex">
            <div class="rsf-table-cell col-12"></div>
            <div class="rsf-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
              <div class="flex justify-center items-center ar-gap mx-auto w-fit-content" v-show="product.ARData.modelAvailable" @click="prepareARView(product.ARData)">
                <a class="color-1 open-sans-semibold h6 lg-h5 compare-ar-link pointer">{{$t('rsf_seeTank')}}</a>
                <svg class="comapre-ar-link-icon pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" style="margin-bottom: -1px;" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 19.5002L18.5 20.6598" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M3.01367 19.1461L5.5 19.8753" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M20.9812 5.16974L18.1881 4.23484L17.7402 4.08495" stroke="#1867FF" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M3 6.00031L6 5.2177" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M20.9922 5.16986L21 9.00018" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M20.9922 16.5002L21 19.5002" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M3 6.00031L3 9.50031" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M3.01367 16.0002L3.01367 19.146" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M5.45508 17.0457L14.4551 19.5003L18.546 17.8639" stroke="#1867FF" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.45508 8.04572L14.4551 6.00026L18.546 7.22753" stroke="#1867FF" stroke-width="1.3"
                    stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M5.45508 12.5457H14.4551H18.5458" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M14.4551 6.40948L14.4551 19.0913" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M18.5527 7.2276L18.5527 17.864" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M5.45508 8.04572L5.45508 17.0457" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M9.54688 12.9549V17.864" stroke="#1867FF" stroke-width="1.3" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </TableSection>

          <TableSection :title=rsf_sysVols_title>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_total') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysVol_TotalSystemWaterVol_MetricLiter"
                metricSuffix=" L"
                :imperialValue="product.SysVol_TotalSystemWaterVol_ImperialGallon"
                imperialSuffix=" gal"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_aqVolume') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysVol_DisplayTankWaterVol_MetricLiter"
                metricSuffix=" L"
                :imperialValue="product.SysVol_DisplayTankWaterVol_ImperialGallon"
                imperialSuffix=" gal"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_cabinet') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysVol_InCabinetSumpWaterVol_MetricLiter"
                :imperialValue="product.SysVol_InCabinetSumpWaterVol_ImperialGallon"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_rear') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysVol_RearSumpVol_MetricLiter"
                metricSuffix=" L"
                :imperialValue="product.SysVol_RearSumpVol_ImperialGallon"
                imperialSuffix=" gal"
              />
            </div>
          </TableSection>

          <TableSection :title="$t('rsf_total_dimensions')">
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_tank_length') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysDim_DisplayTankLength_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SysDim_DisplayTankLength_ImperialInch"
                imperialSuffix=' "'
              />
              
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_tank_width') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysDim_DisplayTankWidth_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SysDim_DisplayTankWidth_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_tank_height') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysDim_DisplayTankHeight_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SysDim_DisplayTankHeight_ImperialInch"
                imperialSuffix=' "'
              />
              
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_total_height') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SysDim_TotalSystemHeight_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SysDim_TotalSystemHeight_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
          </TableSection>

          <TableSection :title=rsf_glass>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ rsf_front_glass }}</h3>
              </div>
              <CalcCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.GLA_UltraClearFrontGlass_MetricCm"
                metricSuffix=" mm"
                :imperialValue="product.GLA_UltraClearFrontGlass_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ rsf_side_glass }}</h3>
              </div>
              <CalcCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.GLA_UltraClearSideGlass_MetricCm"
                metricSuffix=" mm"
                :imperialValue="product.GLA_UltraClearSideGlass_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ rsf_bottom_glass }}</h3>
              </div>
              <CalcCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.GLA_UltraClearBottomGlass_MetricCm"
                metricSuffix=" mm"
                :imperialValue="product.GLA_UltraClearBottomGlass_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
          </TableSection>

          <TableSection :title="$t('rsf_lighting_title')">
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_reefled') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Lig_ReefLedAdvancedLightSystem_Text"
                :imperialValue="product.Lig_ReefLedAdvancedLightSystem_Text"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_nominalTotal') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Lig_NominalTotalOutput_Text"
                :imperialValue="product.Lig_NominalTotalOutput_Text"
              />
              
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sysVols_integratedWifi') }}</h3>
              </div>
              <BooleanTableCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :value="product.Lig_IntegratedWifiControlViaReefBeatApp_YesNo == 'Y' ? true : false" 
              />
            </div>
          </TableSection>

          <TableSection :title="$t('rsf_total_circulation')">
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_total_circulation_table_heading') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.TotCir_TotalCirculation_MetricCm"
                :imperialValue="product.TotCir_TotalCirculation_ImperialInch"
              />
            </div>
          </TableSection>

          <TableSection :title="$t('rsf_return_sump')">
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_return_sump_main_system_pump') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SumRetSys_MainSystemPump_MetricCm"
                :imperialValue="product.SumRetSys_MainSystemPump_ImperialInch"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_return_sump_number_of_outlets') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SumRetSys_NumberOfOutlets_Text"
                :imperialValue="product.SumRetSys_NumberOfOutlets_Text"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_return_sump_built_in_surface_skimmer') }}</h3>
              </div>
              <BooleanTableCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :value="product.SumRetSys_BuiltInSurfaceSkimmerWithRemovableCombs_YesNo == 'Y' ? true : false" 
              />
            </div>
          </TableSection>

          <TableSection :title=rsf_filtration_title>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_filtration_protein') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Filt_ProteinSkimmer_MetricLiter"
                :imperialValue="product.Filt_ProteinSkimmer_ImperialGallon"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_compare_table_head_reefmat') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Filt_reefmat"
                :imperialValue="product.Filt_reefmat"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_filtration_mechanicalFil') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Filt_MechanicalFiltration_YesNo == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_filtration_micronFilter') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Filt_MicronFilterBags_Text"
                :imperialValue="product.Filt_MicronFilterBags_Text"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_filtration_mediacup') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Filt_FilterMediaCup_Text"
                :imperialValue="product.Filt_FilterMediaCup_Text"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_filtration_carbonFiltration') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Filt_CarbonFiltrationMedia_Text"
                :imperialValue="product.Filt_CarbonFiltrationMedia_Text"
              />
            </div>
          </TableSection>

          <TableSection :title="$t('rsf_sump')">
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_media_chamber') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Sump_ReefmatReady"
                :imperialValue="product.Sump_ReefmatReady"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_sump_adjustable_skimmer_chamber') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Sump_AdjustableHeightSkimmerChamber == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_refugium_ready') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Sump_RefugiumReady == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_RO_reservoir_volume') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Sump_ROReservoirVolume_MetricLiter"
                :imperialValue="product.Sump_ROReservoirVolume_ImperialGallon"
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_split_sump') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Sump_SplitSump"
                :imperialValue="product.Sump_SplitSump"
              />
            </div>
          </TableSection>

          <TableSection :title="$t('rsf_sump_dimensions')">
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_water_height') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SumDim_WaterHeight_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SumDim_WaterHeight_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_filtration_chamber') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SumDim_SkimmerChamber_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SumDim_SkimmerChamber_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_pump_chamber') }}</h3>
              </div>
              <UnitCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.SumDim_PumpChamber_MetricCm"
                metricSuffix=" cm"
                :imperialValue="product.SumDim_PumpChamber_ImperialInch"
                imperialSuffix=' "'
              />
            </div>
          </TableSection>

          <TableSection :title=rsf_cabinet_title>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_cabinet_marine') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Cab_MarineSpecAnodizedAluminiumFrame == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_splash_resistant_doors') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Cab_SplashResistantEpoxyPaintedDoors == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_cabinet_chiller') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Cab_ChillerReady == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_cabinet_heavyDuty') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Cab_HeavyDutyLevelablePlywoodCabinet == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_quick_release_doors') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.Cab_QuickReleaseCabinetDoorsHingers == 'Y' ? true : false" />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_compare_table_head_slide_out_panel') }}</h3>
              </div>
              <StringCell 
                v-for="product in detailedProducts" 
                :key="product.id"
                :measurement="measurement"
                :metricValue="product.Cab_slideOutControlPanel"
                :imperialValue="product.Cab_slideOutControlPanel"
              />
            </div>
          </TableSection>

          <TableSection :title=rsf_reefato>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_reefato_kit') }}</h3>
              </div>
              <BooleanTableCell v-for="product in detailedProducts" :key="product.id"
                :value="product.reAtPl_ReefAtoPlusKit == 'Y' ? true : false"  />
            </div>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_reefato_ready') }}</h3>
              </div>
              <div class="rsf-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
                <p class="open-sans-var h5 color-black center">{{ product.reAtPl_ReefAtoPlusReady ? product.reAtPl_ReefAtoPlusReady : '-'  }}</p>
              </div>
            </div>
          </TableSection>

          <TableSection :title=rsf_accesories_title>
            <div class="flex">
              <div class="rsf-table-cell col-12">
                <h3 class="open-sans-regular color-black h5">{{ $t('rsf_accesories_integratedPower') }}</h3>
              </div>
              <div class="rsf-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
                <p class="open-sans-var h5 color-black center">{{ product.ACC_IntegratedPowerCenter }}</p>
              </div>
            </div>
          </TableSection>


          <div class="rsf-compare-table-heading-row flex justify-center items-center gap3" v-show="siteLanguage != 'cn' && siteLanguage != 'ja'">
            <h3 class="sm-h4 h5">{{ $t('rsf_recommen_price') }}</h3>
            <CountrySelect :updateCountry="updateCountry" :siteLanguage="siteLanguage" />
          </div>

          <div class="flex" v-show="selectedCountry && displayFirstRow" v-if="siteLanguage != 'cn' && siteLanguage != 'ja'">
            <div class="rsf-table-cell col-12">
              <h3 class="open-sans-regular color-black h5">{{$t('rsf_compare_prices_white')}}</h3>
            </div>
            <div class="rsf-table-cell col-12 py2" v-for="product in detailedProducts" :key="product.id">
              <p class="open-sans-var h5 color-black center">{{ product.priceList.firstElement ? product.priceList.firstElement.RetailPrice == '0.0000' ? 'N/A' : formatCurrency(product.priceList.firstElement.Currency, product.priceList.firstElement.RetailPrice, product.priceList.firstElement.IsoCode ) : 'N/A' }}</p>
            </div>
          </div>
          <div class="flex" v-show="selectedCountry && displaySecondRow" v-if="siteLanguage != 'cn' && siteLanguage != 'ja'">
            <div class="rsf-table-cell col-12">
              <h3 class="open-sans-regular color-black h5">{{$t('rsf_compare_prices_black')}}</h3>
            </div>
            <div class="rsf-table-cell col-12 py2" v-for="product in detailedProducts" :key="product.id">
              <p class="open-sans-var h5 color-black center">{{ product.priceList.secondElement ? product.priceList.secondElement.RetailPrice == '0.0000' ? 'N/A' : formatCurrency(product.priceList.secondElement.Currency, product.priceList.secondElement.RetailPrice, product.priceList.firstElement.IsoCode) : 'N/A'  }}</p>
            </div>
          </div>
          <div class="flex" v-show="selectedCountry && displayThirdRow" v-if="siteLanguage != 'cn' && siteLanguage != 'ja'">
            <div class="rsf-table-cell col-12">
              <h3 class="open-sans-regular color-black h5">{{$t('rsf_compare_prices_no_cabinet')}}</h3>
            </div>
            <div class="rsf-table-cell col-12 py2" v-for="product in detailedProducts" :key="product.id">
              <p class="open-sans-var h5 color-black center">{{ product.priceList.thirdElement ? product.priceList.thirdElement.RetailPrice == '0.0000' ? 'N/A' : formatCurrency(product.priceList.thirdElement.Currency, product.priceList.thirdElement.RetailPrice, product.priceList.firstElement.IsoCode) : 'N/A' }}</p>
            </div>
          </div>

          <div class="flex rsf-compare-table-btn-row" v-show="siteLanguage != 'cn'">
            <div class="rsf-table-cell col-12">
            </div>
            <div class="rsf-table-cell col-12" v-for="product in detailedProducts" :key="product.id">
              <a class="rsf-btn rsf-btn-primary color-white bg-color-1 mx-auto" :href="product.buttonTarget == 'my-reefer' ? buttonLinkMyReefer : buttonLinkStorefinder ">{{ product.buttonTarget == 'my-reefer' ?
                  rsf_build_with_myrefeer : rsf_whereToBuy
              }}</a>
            </div>
          </div>


        </div>
      </div>
      <div v-show="siteLanguage != 'cn'" class="rsf-compare-table-dual-btn-row">
            <div class="rsf-table-cell flex justify-center flex-wrap" style="column-gap: 2rem">
              <a v-if="hasMyReefer" class="rsf-btn rsf-btn-primary bg-color-1 color-white mb2 mt2 h5" style="white-space: normal;" :href="buttonLinkMyReefer">{{ $t('rsf_build_with_myrefeer') }}</a>
              <a class="rsf-btn rsf-btn-tertiary color-1 h5" :href="buttonLinkStorefinder">{{ $t('rsf_whereToBuy') }}</a>
            </div>
          </div>

    </div>
    <CompareModal ref="compare-ar-modal">
      <p class="h1 center open-sans-bold color-black pt2 px2 pb2">{{ $t('compare_scan_qr_line') }}</p>
      <img :src="arQRCode" class="contain col-12 block mx-auto pb3" style="max-width: 300px; height: 300px;" />
    </CompareModal>
  </div>
</template>
  
<script>
import axios from 'axios'
import { EventBus } from '@/event-bus'

import Rating from './Rating.vue'
import CompareTankDropdown from './ComapreTankDropdown.vue'
import TableSection from './TableSection.vue'
import BooleanTableCell from './BooleanTableCell.vue'
import CountrySelect from './CountrySelect.vue'
import StringCell from './StringCell.vue'
import UnitCell from './UnitCell.vue'
import CalcCell from './CalcCell.vue'
import CompareModal from './CompareModal.vue'
export default {
  name: 'Compare',
  data() {
    return {
      detailedProducts: [],
      loading: true,
      selectedCountry: false,
      fetchedProducts: false,
      rsf_seeTank_Title: this.$t('rsf_seeTank_Title'),
      rsf_sysVols_title: this.$t('rsf_sysVols_title'),
      rsf_filtration_title: this.$t('rsf_filtration_title'),
      rsf_cabinet_title: this.$t('rsf_cabinet_title'),
      rsf_accesories_title: this.$t('rsf_accesories_title'),
      rsf_build_with_myrefeer: this.$t('rsf_build_with_myrefeer'),
      rsf_whereToBuy: this.$t('rsf_whereToBuy'),
      rsf_display_tank_dimensions: this.$t('rsf_display_tank_dimensions'),
      rsf_total_dimensions: this.$t('rsf_total_dimensions'),
      rsf_glass: this.$t('rsf_glass'),
      rsf_front_glass: this.$t('rsf_front_glass'),
      rsf_side_glass: this.$t('rsf_side_glass'),
      rsf_bottom_glass: this.$t('rsf_bottom_glass'),
      rsf_reefato: this.$t('rsf_reefato'),
      // Ar Links
      arQRCode: '',
      chinaVariant: process.env.VUE_APP_RSF_CHINA_VARIANT,
        /* CG language based  urls for button */
      buttonLinkStorefinder: String,
      buttonLinkMyReefer: String,
    }
  },
  props: {
    selectedProducts: Array,
    unit: String,
    categories: Array,
    updateSelectedProducts: Function,
    measurement: String,
    formatCurrency: Function,
    computedHiCap: Number, 
    computedLowCap: Number,
    siteLanguage: String,

  
  },
  components: {
    Rating,
    CompareTankDropdown,
    TableSection,
    BooleanTableCell,
    CountrySelect,
    StringCell,
    CalcCell,
    UnitCell,
    CompareModal
  },
  methods: {
    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    },
    prepareARView(ARData){
        let device = this.getMobileOperatingSystem();

        if(device == 'Windows Phone' || device == 'unknown'){
          this.arQRCode = ARData.auto_qr;
          this.$refs['compare-ar-modal'].open();
        }

        let templateLink = ARData.link.replace('/en/', `/${this.siteLanguage}/`)

        if(device == 'Android'){
            window.location.href = templateLink;
        }

        if(device == 'iOS'){
            window.location.href = templateLink;
        }


    },
    computeProductImg(product) {
      return {
        img: product.img && require(`../assets/images/${product.img}`)
      }
    },
    fetchDetailedData() {
      console.log(this.selectedCountry)
      this.loading = true
      let currentObj = this;
      this.detailedProducts = [];
      let params = {
        productIdsList: [],
        lang: this.siteLanguage,
        isocode: this.selectedCountry.isocode2
      }
      for (let i = 0; i < this.selectedProducts.length; i++) {
        params.productIdsList.push(this.selectedProducts[i].id)
      }
      setTimeout(() => {
        axios
          .post(process.env.VUE_APP_RSF_API + "/public-api/comparisons/get-product-details/json", params)
          .then(function (response) {
            let fetchedProducts = response.data.listOfProducts;
            setTimeout(() => { currentObj.loading = false }, 500)
            for (let x = 0; x < fetchedProducts.length; x++) {
              currentObj.detailedProducts.push(fetchedProducts[x])
            }
            currentObj.updateProductImgs();
            currentObj.getRetailPrice();

          })
          .catch(function (error) {

          });
      }, 300)
    },
    updateCountry(v) {
      //console.log(v)
      if(v != undefined){
        this.selectedCountry = v;
        this.getRetailPrice();
      }
    },
    getRetailPrice() {
      let that = this;
      if(this.detailedProducts.length < 1){return;}
      for (let i = 0; i < this.detailedProducts.length; i++) {
        let url = process.env.VUE_APP_RSF_API + '/public-api/prices/get-retailprice-compare/8/' + this.detailedProducts[i].RNumber + '/' + this.selectedCountry.isocode2 + '/json'
        axios
          .get(url)
          .then(function (response) {
            // that.detailedProducts[i].RetailPrice = response.data.RetailPrice
            // that.detailedProducts[i].Currency = response.data.Currency
            that.detailedProducts[i].priceList = response.data.priceList;
            that.fetchedProducts = true;
          })
          .catch(function (error) {

          });
      }
    },
    computeProductImg(product) {
      return product.ImageUrl && require(`../assets/images/${this.product.ImageUrl}`)
    },
    updateProductImgs() {
      for (let i = 0; i < this.selectedProducts.length; i++) {
        this.detailedProducts[i].ImageUrl = this.selectedProducts[i].ImageUrl
      }
    },
    isNumeric(value) {
      return Number.isInteger(value);
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.selectedProducts.length <= 0) {
      this.$router.push('/')
    }
    // this.fetchDetailedData();

    this.buttonLinkStorefinder = 'https://g1.redseafish.com/super-store-finder/';
    this.buttonLinkMyReefer = 'https://g1.redseafish.com/myreefer/';

    if(this.siteLanguage == "de" || this.siteLanguage == "fr" || this.siteLanguage == "ja") {
         this.buttonLinkStorefinder = 'https://g1.redseafish.com/' + this.siteLanguage + '/super-store-finder/';
         this.buttonLinkMyReefer = 'https://g1.redseafish.com/' + this.siteLanguage + '/myreefer/';

    }


  },
  computed: {
    hasMyReefer(){
      let hasMyReeferBtn = 0
      for(let i = 0; i < this.detailedProducts.length; i++){
        if(this.detailedProducts[i].buttonTarget == 'my-reefer'){
          hasMyReeferBtn++
        }
      }
      if(hasMyReeferBtn == 0){
        return false
      }else{
        return true
      }
    },
    displayFirstRow(){
      let itemsInRow = 0;
      for(let i = 0; i < this.detailedProducts.length; i++){
        if(this.detailedProducts[i].priceList.firstElement){
          itemsInRow++
        }
      }
      if(itemsInRow > 0){
        return true;
      }
      return false;
    },
    displaySecondRow(){
      let itemsInRow = 0;
      for(let i = 0; i < this.detailedProducts.length; i++){
        if(this.detailedProducts[i].priceList.secondElement){
          itemsInRow++
        }
      }
      if(itemsInRow > 0){
        return true;
      }
      return false;
    },
    displayThirdRow(){
      let itemsInRow = 0;
      for(let i = 0; i < this.detailedProducts.length; i++){
        if(this.detailedProducts[i].priceList.thirdElement){
          itemsInRow++
        }
      }
      if(itemsInRow > 0){
        return true;
      }
      return false;
    },
    // displayATORow(){
    //   let itemsInRow = 0; 
    //   for(let i = 0; i < this.detailedProducts.length; i++){
    //     if(this.detailedProducts[i].reAtPl_ReefAtoPlusKit == 'Y'){
    //       itemsInRow++
    //     }
    //   }
    //   if(itemsInRow > 0){
    //     return true;
    //   }
    //   return false;
    // },
    displayARRow(){
      if(process.env.VUE_APP_RSF_CHINA_VARIANT == 'true'){
        return false;
      }
      let itemsInRow = 0; 
      for(let i = 0; i < this.detailedProducts.length; i++){
        if(this.detailedProducts[i].ARData.modelAvailable){
          itemsInRow++
        }
      }
      if(itemsInRow > 0){
        return true;
      }
      return false;
    }
  },
  watch: {
    selectedProducts(newValue, oldValue) {
      console.log('selectedProducts changed')
      this.fetchDetailedData()
      // BUG: if ids of selected products are the same both get updated to new  selectedProduct. NO DUPLICATE IDS
    },
    selectedCountry(newValue, oldValue){
      console.log('selected country changed')
      console.log('new value')
      console.log(newValue)
      console.log('old value')
      console.log(oldValue)
      if(newValue != oldValue && newValue != undefined && this.fetchedProducts == false){
        console.log('new value != old Value & fetchedProducts = false')
        this.fetchDetailedData();
      }
    }
  }

}
</script>
  
<style scoped>
.compare-ar-link{
  text-decoration: underline; 
  text-decoration-thickness: 1px;
  text-underline-offset: 1.5px;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
}

.ar-gap{
  gap: 6px;
}

@media(min-width: 1024px){
  .ar-gap{
    gap: 1px;
  }
}

@media(max-width: 490px){
   .compare-ar-link{
    font-size: calc(0.5rem + 1.05vw * 0.4);
  }

  .comapre-ar-link-icon{
    width: 16px;
  }
}
@media(max-width: 330px){
  .comapre-ar-link-icon{
    width: 12px;
  }
  .ar-gap{
    gap: 2px;
  }
}

@media(max-width: 600px){
  .rating-cell{
    height: 24px;
  }
}
</style>
  