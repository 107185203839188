<template>
  <div>
    <div v-if="isDevSystem == 'true'" class="py2 bg-color-6 center color-white">{{ devWarnMsg }}</div>
    <main>
      <router-view 
        :selectedProducts="selectedProducts" 
        :categories="categories" 
        :measurement="measurement"
        :toggleSelectedProduct="toggleSelectedProduct" 
        :changeMeasurement="changeMeasurement"
        :updateSelectedProducts="updateSelectedProducts" 
        :updateUpperCap="updateUpperCap"
        :updateLowerCap="updateLowerCap" 
        :computedHiCap="computedHiCap" 
        :computedLowCap="computedLowCap" 
        :formatCurrency="formatCurrency"
        :loadingCategories="loadingCategories"
        :siteLanguage="siteLanguage"
      >
      </router-view>
      <LightBox :showModal="showModal" :toggleModal="toggleModal" :modalMessage="modalMessage"
        :modalHeading="modalHeading" />
    </main>
  </div>
</template>

<script>

import LightBox from './components/LightBox.vue'
import axios from "axios";

export default {
  name: 'App',
  components: {
    LightBox
  },
  props: ["siteLanguage"],
  mounted() {
    //console.log('LANGUAGE ++++++++')
    //console.log(this.siteLanguage)
  },
  data() {
    return {
      isDevSystem: process.env.VUE_APP_RSF_DEV_SYSTEM_WARNING,
      devWarnMsg: process.env.VUE_APP_RSF_DEV_SYSTEM_MESSAGE,
      measurement: 'metric',
      upperCap: 10,
      lowerCap: 1,
      showModal: false,
      modalHeading: '',
      modalMessage: '',
      categories: [],
      selectedProducts: [],
      loadingCategories: true,
      rsf_oops: this.$t('rsf_oops'),
      rsf_3_models: this.$t('rsf_3_models'),
      rsf_4_models: this.$t('rsf_4_models')
    }
  },
  created() {

    //console.log('checking for locale')
    if (this.siteLanguage == '') {
      this.$i18n.locale = 'en';
      this.switchLocale();
    } else {
      this.$i18n.locale = this.siteLanguage;
      this.switchLocale();
    }

    this.fetchData();

  },
  computed: {
    computedHiCap() {
      if (this.upperCap == 1) { 
        this.removeUnfittingTanks(0, 0)
        return 0; 
      }
      if (this.upperCap == 2) { 
        this.removeUnfittingTanks(50, 0)
        return 50; 
      }
      if (this.upperCap == 3) { 
        this.removeUnfittingTanks(75, 0)
        return 75; 
      }
      if (this.upperCap == 4) { 
        this.removeUnfittingTanks(100, 0)
        return 100; 
      }
      if (this.upperCap == 5) { 
        this.removeUnfittingTanks(125, 0)
        return 125; 
      }
      if (this.upperCap == 6) { 
        this.removeUnfittingTanks(150, 0)
        return 150; 
      }
      if (this.upperCap == 7) { 
        this.removeUnfittingTanks(175, 0)
        return 175; 
      }
      if (this.upperCap == 8) { 
        this.removeUnfittingTanks(200, 0)
        return 200; 
      }
      if (this.upperCap == 9) { 
        this.removeUnfittingTanks(225, 0)
        return 225; 
      }
      if (this.upperCap == 10) { 
        this.removeUnfittingTanks(999, 0)
        return 999; 
      }
    },
    computedLowCap() {
      if (this.lowerCap == 1) { 
        this.removeUnfittingTanks(999 , 0)
        return 0; 
      }
      if (this.lowerCap == 2) { 
        this.removeUnfittingTanks(999 , 50)
        return 50; 
      }
      if (this.lowerCap == 3) { 
        this.removeUnfittingTanks(999, 75)
        return 75; 
      }
      if (this.lowerCap == 4) { 
        this.removeUnfittingTanks(999, 100)
        return 100; 
      }
      if (this.lowerCap == 5) { 
        this.removeUnfittingTanks(999, 125)
        return 125; 
      }
      if (this.lowerCap == 6) { 
        this.removeUnfittingTanks(999, 150)
        return 150; 
      }
      if (this.lowerCap == 7) { 
        this.removeUnfittingTanks(999, 175)
        return 175; 
      }
      if (this.lowerCap == 8) { 
        this.removeUnfittingTanks(999, 200)
        return 200; 
      }
      if (this.lowerCap == 9) { 
        this.removeUnfittingTanks(999, 225)
        return 225; 
      }
      if (this.lowerCap == 9) { 
        this.removeUnfittingTanks(999, 999)
        return 999; 
      }
    }
  },
  methods: {

    switchLocale() {

      var locale = this.$i18n.locale;
      const to = this.$router.resolve({ params: { lang: locale } })
      this.$router.push(to.location).catch(err => {
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          //console.log(err);
        }
      })
    },
    removeUnfittingTanks(hiCap, lowCap){
      for(let i = 0; i< this.selectedProducts.length; i++){
        if(hiCap < this.selectedProducts[i].LengthMetric || lowCap > this.selectedProducts[i].LengthMetric){
          //console.log('Tank doesnt fit anymore. Remove it.');
          this.selectedProducts.splice(i, 1);
        }
      }
    },
    formatCurrency(currency, price, isocode) {

      let hasDecimal = false;
      let splitPrice = price.toString().split('.');
      if(parseFloat(splitPrice[1]) != 0 && splitPrice.length > 1){
        hasDecimal = true;
      }

      if (currency == 'EUR') {
        if(!hasDecimal){
          return `€${parseFloat(price)}`
        }else{
          let roundedPrice = parseFloat(price).toFixed(2).toString();
          let euroPrice = roundedPrice.split('.');
          let commaPrice = euroPrice[0] + ',' + euroPrice[1];
          return `€${commaPrice}`
        }
      }
      if (currency == 'JPY') {
        return `¥${price}`
      }
      if (currency == 'AUD') {
        if(!hasDecimal){
          return `$${parseFloat(price)}`
        }else{
          return `$${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'USD' && isocode == 'CA') {
        if(!hasDecimal){
          return `USD ${parseFloat(price)}`
        }else{
          return `USD${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'USD') {
        if(!hasDecimal){
          return `$${parseFloat(price)}`
        }else{
          return `$${parseFloat(price).toFixed(2)}`
        }
      }

      if (currency == 'GBP') {
        if(!hasDecimal){
          return `£${parseFloat(price)}`
        }else{
          return `£${parseFloat(price).toFixed(2)}`
        }
      }
    },
    toggleModal() {
      this.showModal = !this.showModal
    },
    fetchData() {
      let currentObj = this;
      this.loadingCategories = true
      setTimeout(() => {
        axios
          .post(process.env.VUE_APP_RSF_API + "/public-api/comparisons/get-comparison/8/json", {lang: this.siteLanguage})
          .then(function (response) {
            currentObj.categories = response.data.dataCategories;

            //edge case: remove desktop category for chinese instances
            if(currentObj.siteLanguage == 'cn'){
              for(let i = 0; i < currentObj.categories.length; i++){
                if(currentObj.categories[i].id == '12'){ // id of desktop category
                  currentObj.categories.splice(i, 1);
                }
              }
            }
            setTimeout(() => { currentObj.loadingCategories = false }, 500)
          })
          .catch(function (error) {

          });
      }, 300)
    },
    toggleSelectedProduct(product) {
      let screenWidth = window.innerWidth
      for (let i = 0; this.selectedProducts.length > i; i++) {
        if (this.selectedProducts[i].id == product.id) {
          this.selectedProducts.splice(i, 1)
          //console.log(this.selectedProducts)
          return
        }
      }
      if (this.selectedProducts.length == 3 && screenWidth < 1024) {
        this.showModal = true
        this.modalHeading = this.$t('rsf_oops')
        this.modalMessage = this.$t('rsf_3_models')
        return
      }
      if (this.selectedProducts.length == 4 && screenWidth > 1024) {
        this.showModal = true
        this.modalHeading = this.$t('rsf_oops')
        this.modalMessage = this.$t('rsf_4_models')
        return
      }
      this.selectedProducts.push(product);
    },
    changeMeasurement(val) {
      //console.log('method fired: change unit')
      //console.log(val)
      if (val) {
        this.measurement = 'metric'
      } else {
        this.measurement = 'imperial'
      }
    },
    updateSelectedProducts(newValue, oldValue) {
      //console.log('updateSelectedProduct')
      //console.log(newValue)
      //console.log(oldValue)
      let dummyArray = this.selectedProducts
      for (let i = 0; i < dummyArray.length; i++) {
        if (this.selectedProducts[i].id == oldValue.id) {
          this.selectedProducts.splice(i, 1, newValue)
        }
      }
    },
    updateUpperCap(val) {
      this.upperCap = val
    },
    updateLowerCap(val) {
      this.lowerCap = val
    },
  }
}
</script>

<style>
@import './css/redsea-compare.css';

#app {
  font-family: 'Open Sans', sans-serif;
  min-height: 100vh;

}
</style>
