<template>
  <div class="rsf-table-cell relative col-12">
    <svg v-show="value" class="mx-auto" width="25" height="24" viewBox="0 0 25 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.8828" cy="12" r="11" fill="#18E098" />
      <path d="M6.88281 12.5L11.3828 17L18.8828 8" stroke="white" stroke-width="2" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
    <svg v-show="!value" class="mx-auto" width="25" height="24" viewBox="0 0 25 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.3828" cy="12" r="11" fill="#909CA5" />
      <rect width="14" height="2" rx="1" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 7.9834 17.5)"
        fill="white" />
      <rect x="16.4685" y="17.5" width="14" height="2" rx="1" transform="rotate(-135 16.4685 17.5)" fill="white" />
    </svg>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'BooleanTabelCell',
  props: {
    value: Boolean,
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
