<template>
  <div class="star-wrapper relative">
    <div class="star-clip" :style="`clip-path: url(#rsf-star-clip-${clipId})`">
      <div class="star-inner bg-color-5" :style="`width: ${fill}%`"></div>
    </div>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" class="star-outline">
      <path
        d="M7.95166 0.740616L9.7381 4.36033C9.88376 4.65548 10.1653 4.86005 10.491 4.90738L14.4856 5.48782L11.5951 8.30538C11.3594 8.53512 11.2519 8.86612 11.3075 9.19051L11.9899 13.169L8.417 11.2906C8.12568 11.1374 7.77764 11.1374 7.48632 11.2906L3.91344 13.169L4.5958 9.19051C4.65144 8.86612 4.54389 8.53512 4.30821 8.30538L1.41769 5.48782L5.41229 4.90738C5.738 4.86005 6.01956 4.65548 6.16522 4.36033L7.95166 0.740616Z"
        stroke="#FFC700" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
      <defs>
        <clipPath :id="'rsf-star-clip-' + clipId">
          <path
            d="M7.95166 0.740616L9.7381 4.36033C9.88376 4.65548 10.1653 4.86005 10.491 4.90738L14.4856 5.48782L11.5951 8.30538C11.3594 8.53512 11.2519 8.86612 11.3075 9.19051L11.9899 13.169L8.417 11.2906C8.12568 11.1374 7.77764 11.1374 7.48632 11.2906L3.91344 13.169L4.5958 9.19051C4.65144 8.86612 4.54389 8.53512 4.30821 8.30538L1.41769 5.48782L5.41229 4.90738C5.738 4.86005 6.01956 4.65548 6.16522 4.36033L7.95166 0.740616Z"
            stroke="#FFC700" stroke-linecap="round" stroke-linejoin="round" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
// import axios from 'axios'
// import { EventBus } from '@/event-bus'

export default {
  name: 'Star',
  props: {
    fill: Number,
    clipId: String
  },
  data() {
    return {
    }
  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
